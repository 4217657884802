import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { useArray, useNumber } from "react-hanger"; //https://github.com/kitze/react-hanger
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import { Checkbox } from "@fluentui/react";

//Custom Hooks
import { useAmplifyGet, useAmplifyPost } from "../hooks/useAmplifyApi";
import { useDidMountEffect } from "../hooks/useDidMountEffect";
import { useInterval } from "../hooks/useInterval";

//Components
import { Sidebar, SidebarGroup } from "../components/Sidebar";
import Bubble from "../components/Bubble";
import { Button, StyledInput, ToggleButton } from "../components/BasicControls";
import { Accordion } from "../components/Accordion";
import { StreamSelector } from "../components/StreamSelector";
import { ContentWithSidebarPage } from "../components/Page";
import { FlexboxRows } from "../components/Layout";

//Bubbles
import PendingBubble from "../components/gilgamesh_bubbles/PendingBubble";
import StagedBubble from "../components/gilgamesh_bubbles/StagedBubble";
import PollingBubble from "../components/gilgamesh_bubbles/PollingBubble";
import InitiatingBubble from "../components/gilgamesh_bubbles/InitiatingBubble";
import EndStateBubble from "../components/gilgamesh_bubbles/EndStateBubble";
import useRecentFeednames from "../hooks/useRecentFeedNames";
import SaveAsTagButton from "../components/SaveAsTagButton";

export default function StepFunctions(props) {
  // Form States
  const [streamSelector, setStreamSelector] = useState("");
  const [executionRegion, setExecutionRegion] = useState("us-west-2");
  const [stagedFunction, setStagedFunction] = useState("");
  const [recentFeeds, addRecentFeed] = useRecentFeednames();

  // Execution States
  const streamNamesPendingValidation = useArray([]);
  const stagedForExecution = useArray([]);
  const executionsAwaitingArn = useArray([]);
  const runningExecutions = useArray([]);
  const succeededExecutions = useArray([]);
  const failedExecutions = useArray([]);

  // Filter States
  const filteredRegions = useArray([]);
  const filteredSubregions = useArray([]);

  // Dropdown Options
  const regionOptions = [
    { value: "us-west-2", label: "us-west-2" },
    { value: "us-east-1", label: "us-east-1" },
  ];

  const stepFunctionOptions = [
    { value: "reconfigure_live", label: "Reconfigure Live" },
    { value: "disable_dagon_monitoring", label: "Disable Dagon Monitoring" },
    { value: "enable_dagon_monitoring", label: "Enable Dagon Monitoring" },
    {
      value: "restart_eml_encoder_galileo",
      label: "Restart EML Service",
      region: "us-east-1",
    },
    { value: "unhibernate_stream", label: "Unhibernate Stream" },
    { value: "hibernate_stream", label: "Hibernate Stream" },
    { value: "enable_watermark", label: "Enable Watermark" },
    { value: "disable_watermark", label: "Disable Watermark" },
    { value: "dr_reconfigure_live", label: "DR Failover" },
    { value: "rollback_dr_reconfigure_live", label: "DR Failover Rollback" },
    { value: "dr_update_zixi_input_of_an_output", label: "DR TAG Failover" },
    {
      value: "rollback_update_zixi_input_of_an_output",
      label: "DR TAG Rollback",
    },
    { value: "create_appeartv_channel", label: "Create AppearTV Channel" },
    { value: "delete_appeartv_channel", label: "Delete AppearTV Channel" },
    { value: "update_zixi_outputs", label: "Update JP zixi outputs" },
    { value: "reconfigure_live_legacy", label: "Legacy Reconfigure Live" },
    { value: "move_stream", label: "Move Stream"},
  ];

  let streamNames = [];

  function handleStreamSearch(event) {
    let parts = streamSelector.trim().split(" ");
    console.log("Parts", parts);

    parts.map((i) => {
      if (i) {
        //Don't create empty bubbles
        streamNamesPendingValidation.push(
          createBubble({ name: i, status: "Pending" })
        );
      }
    });

    setStreamSelector("");
  }

  function handleStepFunctionSelection(value) {
    //Search through the StepFunctionOptions for the selected item
    const selectedOption = stepFunctionOptions.filter(
      (option) => option.value === value
    )[0];
    //Set selected Region to the 'region' value of the selected stepfunction. OR 'us-west-2' if there is no region
    const selectedRegion =
      regionOptions.filter(
        (option) => option.value === selectedOption.region
      )[0] || regionOptions[0];

    //Set values in the state to update the page
    setStagedFunction(value);
    setExecutionRegion(selectedRegion);
  }

  function runStagedFunctions() {
    //Get bubbles in staged status
    const stagedExecutions = stagedForExecution.value.filter(
      (item) => item.status === "Staged"
    );

    // Map over all bubbles in staged status
    stagedExecutions.map((item) => {
      //Push those to initiating status
      executionsAwaitingArn.push(item);
      //Remove staged executions from staged
      stagedForExecution.removeById(item.id);
    });
  }

  //Log all
  useEffect(() => {
    stagedForExecution.value.map((item) => {
      addRecentFeed(item.name);
      streamNames.push(item.name);
    });
  }, [stagedForExecution]);

  return (
    <ContentWithSidebarPage sidebarWidth="20rem">
      <Sidebar>
        <StreamSelector
          onBlur={handleStreamSearch}
          onChange={(e) => setStreamSelector(e.target.value)}
          value={streamSelector}
        />

        <Select
          options={stepFunctionOptions}
          onChange={({ value, label }) => handleStepFunctionSelection(value)}
          defaultValue={{ value: "", label: "Select a step function" }}
        />
        {stagedFunction ? (
          <Button onClick={runStagedFunctions}>
            Run{" "}
            {stagedForExecution.value.filter((item) => item.status === "Staged")
              .length || null}{" "}
            Executions
          </Button>
        ) : (
          <Button disabled={true}> Please Select a Step Function </Button>
        )}

        <FilterGroup
          realm="North America"
          labels={["iad", "sfo", "pdx"]}
          filterState={filteredRegions}
        />
        <FilterGroup
          realm="Europe"
          labels={["fra", "lhr", "dub"]}
          filterState={filteredRegions}
        />
        <FilterGroup
          realm="Japan"
          labels={["nrt"]}
          filterState={filteredRegions}
        />
        <FilterGroup
          realm="Subregions"
          labels={["1", "2", "3", "4"]}
          filterState={filteredSubregions}
        />
        <div id="sidebarFooter">
          <Select
            options={regionOptions}
            defaultValue={regionOptions[0]}
            value={executionRegion}
            onChange={({ value, label }) =>
              setExecutionRegion({ value, label })
            }
          />
        </div>
      </Sidebar>
      <FlexboxRows>
        <div>
          <SaveAsTagButton streamNames={streamNames} />
        </div>
        <Accordion title="Pending">
          {/* Every item in this list is a stream name which has not been confirmed to exist */}
          {streamNamesPendingValidation.value.map(({ name, id, status }) => (
            // We pass a function to PendingBubble which allows it to push to the "staged" state
            <PendingBubble
              key={id}
              id={id}
              streamName={name}
              createBubble={createBubble}
              pushToStaged={stagedForExecution.push}
              removeFromPending={streamNamesPendingValidation.removeById}
              status={status}
              setStatus={(newStatus) =>
                streamNamesPendingValidation.modifyById(id, {
                  status: newStatus,
                })
              }
            />
            // This bubble should delete itself once it has cleared the "pending" state
          ))}
        </Accordion>

        <Accordion title="Staged">
          {stagedForExecution.value.map(({ name, id, status }) => (
            <StagedBubble
              key={id}
              id={id}
              streamName={name}
              status={status}
              executionRegion={executionRegion.value}
              stagedFunction={stagedFunction}
              regionFilters={filteredRegions}
              subregionFilters={filteredSubregions}
              pushToRunning={executionsAwaitingArn.push}
              removeFromStaged={stagedForExecution.removeById}
              setStatus={(newStatus) =>
                stagedForExecution.modifyById(id, { status: newStatus })
              }
            />
          ))}
          {executionsAwaitingArn.value.map(({ name, id, status }) => (
            <InitiatingBubble
              key={id}
              id={id}
              streamName={name}
              status={status}
              executionRegion={executionRegion.value}
              stagedFunction={stagedFunction}
              regionFilters={filteredRegions}
              subregionFilters={filteredSubregions}
              pushToRunning={runningExecutions.push}
              removeFromInitiating={executionsAwaitingArn.removeById}
              setStatus={(newStatus) =>
                executionsAwaitingArn.modifyById(id, { status: newStatus })
              }
            />
          ))}
        </Accordion>

        <Accordion title="Running">
          {runningExecutions.value.map(
            ({ name, id, execution_arn, status }) => (
              <PollingBubble
                key={id}
                id={id}
                streamName={name}
                execution_arn={execution_arn}
                status={status}
                setStatus={(newStatus) =>
                  runningExecutions.modifyById(id, { status: newStatus })
                }
                removeFromRunning={runningExecutions.removeById}
                pushToComplete={succeededExecutions.push}
                pushToFailed={failedExecutions.push}
              />
            )
          )}
        </Accordion>
        <Accordion title="Failed"></Accordion>
        {failedExecutions.value.map(({ name, id, execution_arn, status }) => (
          <EndStateBubble
            key={id}
            id={id}
            streamName={name}
            execution_arn={execution_arn}
            status={status}
            removeSelf={() => failedExecutions.removeById(id)}
            pushToStaged={stagedForExecution.push}
          />
        ))}

        <Accordion title="Complete">
          {succeededExecutions.value.map(
            ({ name, id, execution_arn, status }) => (
              <EndStateBubble
                key={id}
                id={id}
                streamName={name}
                execution_arn={execution_arn}
                status={status}
                removeSelf={() => succeededExecutions.removeById(id)}
                pushToStaged={stagedForExecution.push}
              />
            )
          )}
        </Accordion>
      </FlexboxRows>
    </ContentWithSidebarPage>
  );
}

function FilterGroup({ realm, labels, filterState }) {
  const styles = {
    display: "flex",
    justifyContent: "flex-start",
  };
  return (
    <SidebarGroup name={realm}>
      <div style={styles}>
        {labels.map((label) => {
          return (
            <RegionFilterToggle
              key={label}
              label={label}
              filterState={filterState}
            />
          );
        })}
      </div>
    </SidebarGroup>
  );
}

function RegionFilterToggle({ label, filterState }) {
  const [checked, setChecked] = useState(false);

  function handleChange() {
    const index = filterState.value.indexOf(label);
    // Here we assume that filterState has the same interface
    // as useArray from react-hangers
    if (index > -1) {
      filterState.removeIndex(index);
      setChecked(false);
    } else {
      filterState.push(label);
      setChecked(true);
    }
  }

  //console.log(filterState.value);

  const xMargin = "10px";

  // We wrap the label and checkbox in a span so that they render
  // side by side
  const spanStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5px",
    marginLeft: xMargin,
    marginRight: xMargin,
  };

  return (
    <span style={spanStyles}>
      <Checkbox name={label} onChange={handleChange} checked={checked} />
      <label htmlFor={label}>{label}</label>
    </span>
  );
}

// console.log(stagedForExecution.value);

/**
 * Create a Bubble with an ID
 *
 * @param {Object} payload
 */
function createBubble(payload) {
  //Generate ID
  const generatedId = uuidv4();

  //Create Object
  const newStream = {
    id: generatedId,
    ...payload,
  };

  return newStream;
}
